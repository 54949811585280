import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "routes/Loadable";

// Page routing
const InsightsPage = Loadable(lazy(() => import("pages/insights")));
const ImpactPage = Loadable(lazy(() => import("pages/impact")));
const SearchPage = Loadable(lazy(() => import("pages/search")));
const FriendsPage = Loadable(lazy(() => import("pages/friends")));
const VotersPage = Loadable(lazy(() => import("pages/voters")));
const BallotsPage = Loadable(lazy(() => import("pages/ballots")));
const WIPPage = Loadable(lazy(() => import("pages/wip")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <ImpactPage />,
    },
    {
      path: "insights",
      element: <InsightsPage />,
    },
    {
      path: "impact",
      element: <ImpactPage />,
    },
    {
      path: "search",
      element: <SearchPage />,
    },
    {
      path: "friends",
      element: <FriendsPage />,
    },
    {
      path: "info",
      element: <WIPPage />,
    },
    {
      path: "voters",
      element: <VotersPage />,
    },
    {
      path: "ballots",
      element: <BallotsPage />,
    },
    {
      path: "privacy",
      element: <WIPPage />,
    },
  ],
};

export default MainRoutes;
