import * as React from "react";
import { useEffect } from "react";

import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { IconWalk } from "@tabler/icons";

import { formatNumber } from "data/utils";
import Cache from "data/cache";
import CustomLink from "./Link";

const useStyles = makeStyles((theme) => ({
  badgePending: {
    "& span": {
      backgroundColor: "orange",
      boxShadow: `0 0 0 orange`,
      animation: `pulse 1.5s ease infinite`,
    },
    "& span:before": {
      borderRadius: "50%",
      border: "4px double lighten(orange, 20%)",
    },
  },
  badgeSuccess: {
    "& span": {
      backgroundColor: "green",
      boxShadow: `0 0 0 green`,
      animation: `pulse 1.5s ease infinite`,
    },
    "& span:before": {
      borderRadius: "50%",
      border: "4px double lighten(green, 20%)",
    },
  },
  listText: {
    "& span": {
      textAlign: "right",
    },
    "& p": {
      textAlign: "right",
    },
  },
}));

export default function LiveCounter() {
  const classes = useStyles();
  const [lastImport, setLastImport] = React.useState();
  const [liveVoters, setLiveVoters] = React.useState(0);
  const [pending, setPending] = React.useState(0);
  const [totalVoters, setTotalVoters] = React.useState(0);

  useEffect(() => {
    Cache.TotalStats().then((data) => {
      setTotalVoters(data.voters.trueTotal);
      setLiveVoters(data.voters.now);
    });
    Cache.ImportsPending().then((results) => {
      const { pending, lastImport } = results;
      setPending(pending);
      setLastImport(lastImport.format("MMM D, YYYY"));
    });
  }, []);

  function subtitle() {
    if (pending > 0) {
      return `Currently importing ballots for ${pending.toLocaleString()} date${
        pending === 1 ? "" : "s"
      }`;
    }
    return `Ballots up-to-date as of ${lastImport}`;
  }

  if (totalVoters === 0) {
    return null;
  }

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      style={{ padding: 0, width: "fit-content" }}
    >
      <CustomLink dest="ballots" style={{ textDecoration: "unset" }}>
        <ListItem style={{ padding: 10, borderRadius: 24 }} button>
          <ListItemText
            primary={`${formatNumber(liveVoters, 1)} Texans voted in Nov 2022`}
            secondary={subtitle()}
            className={classes.listText}
          />
          <ListItemAvatar>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              color="success"
              className={
                pending > 0 ? classes.badgePending : classes.badgeSuccess
              }
            >
              <Avatar style={{ backgroundColor: "transparent" }}>
                <IconWalk />
              </Avatar>
            </Badge>
          </ListItemAvatar>
        </ListItem>
      </CustomLink>
    </List>
  );
}
