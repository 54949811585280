import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { MENU_OPEN } from "store/actions";
import Analytics from "data/analytics";

const CustomLink = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { name, children, external, dest, style } = props;
  if (external) {
    return (
      <a
        ref={ref}
        href={dest}
        target="_blank"
        rel="noreferrer"
        onClick={() => Analytics.oneMore("Link_Clicked", name)}
        style={style}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      ref={ref}
      to={`../${dest}`}
      onClick={() => dispatch({ type: MENU_OPEN, id: dest })}
      style={style}
    >
      {children}
    </Link>
  );
});

export default CustomLink;
