import top from "./top-level";
import tracker from "./tracker";
import resources from "./resources";
import data from "./data";
import other from "./other";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [top, tracker, resources, data, other],
};

export default menuItems;
