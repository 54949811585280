import AnalyticsAPI from "analytics";
import simpleAnalyticsPlugin from "@analytics/simple-analytics";

import Firestore from "./firestore";
import Logger from "./logger";

class AnalyticsWrapper {
  constructor() {
    this.BATCH = "batch";
    this.FRIEND = "friend";
    this.INDIVIDUAL = "individual";

    this.simpleAnalytics = AnalyticsAPI({
      app: "texas-voter-tracker",
      plugins: [
        simpleAnalyticsPlugin({
          customDomain: "impact.texasvotertracker.com",
          hostname: "texasvotertracker.com",
        }),
      ],
    });

    const identifier = localStorage.getItem("Hello");
    this.oneMore("users", "all");
    if (!identifier) {
      localStorage.setItem("Hello", "user! :)");
      this.oneMore("users", "new");
    }
    Logger.transparency(
      `Welcome ${identifier ? "back " : ""}to my site, ${
        identifier ? "" : "new "
      }friend!`
    );
  }

  async pageView(pageName) {
    if (Logger.inDebugMode()) {
      Logger.debug(`Page loaded: ${pageName}`);
      return;
    }
    this.simpleAnalytics.page();
    Firestore.Event("Page_Viewed", pageName);
  }

  async oneMore(event, subtype) {
    if (Logger.inDebugMode()) {
      Logger.debug(`Incrementing events: ${event} (${subtype})`);
      return;
    }
    this.simpleAnalytics.track(event);
    Firestore.Event(event, subtype);
  }
}

const Analytics = new AnalyticsWrapper();
export default Analytics;
