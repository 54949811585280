// assets
import { IconDatabaseImport, IconLicense } from "@tabler/icons";

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const data = {
  id: "data",
  title: "Data",
  type: "group",
  children: [
    {
      id: "voters",
      title: "County Voter Records",
      type: "item",
      url: "/voters",
      icon: IconDatabaseImport,
      breadcrumbs: false,
    },
    {
      id: "ballots",
      title: "State Casted Ballots",
      type: "item",
      url: "/ballots",
      icon: IconLicense,
      breadcrumbs: false,
    },
    // {
    //   id: "privacy",
    //   title: "Privacy",
    //   type: "item",
    //   url: "/privacy",
    //   icon: IconSpy,
    //   breadcrumbs: false,
    // },
  ],
};

export default data;
