export function formatNumber(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

const election_id_to_name = {
  44145: "Primary (D)",
  44146: "Primary (R)",
  44387: "Primary Runoff (D)",
  44376: "Primary Runoff (R)",
  44144: "National Election",
  45706: "General Election",
  47011: "Primary (D)",
  47010: "Primary (R)",
  47058: "Constitutional Amendment",
  47293: "Primary Runoff (D)",
  47292: "Primary Runoff (R)",
  47009: "General Election",
};

export function ElectionToName(election_id) {
  return `${election_id_to_name[election_id]} (${election_id})`;
}
