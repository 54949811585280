// assets
import { IconFriends, IconSearch } from "@tabler/icons";

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const main = {
  id: "tracker",
  title: "Texas Voters",
  type: "group",
  children: [
    {
      id: "search",
      title: "Voter Lookup",
      type: "item",
      url: "/search",
      icon: IconSearch,
      breadcrumbs: false,
    },
    {
      id: "friends",
      title: "Have My Friends Voted?",
      type: "item",
      url: "/friends",
      icon: IconFriends,
      breadcrumbs: false,
    },
    // {
    //   id: "info",
    //   title: "About",
    //   type: "item",
    //   url: "/info",
    //   icon: IconInfoCircle,
    //   breadcrumbs: false,
    // },
  ],
};

export default main;
