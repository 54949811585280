import _ from "lodash";
import moment from "moment";

import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore/lite";

import { maxFriendsLimit } from "store/constant";
import Logger from "./logger";
import Testimony from "./testimony";
import VoterClass from "./voter";

// From Project Settings > General
const firebaseConfig = {
  apiKey: "AIzaSyAl-XbaaFcrTVvtF7VbFcPuikCrZ1kBk-Y",
  authDomain: "texas-voter-tracker.firebaseapp.com",
  projectId: "texas-voter-tracker",
  storageBucket: "texas-voter-tracker.appspot.com",
  messagingSenderId: "942658972732",
  appId: "1:942658972732:web:6971d628d54e742757e739",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

export default class Firestore {
  static async AnonymouslySignIn() {
    return await signInAnonymously(auth).then(() => {
      // Signed in..
      Logger.debug("Signed in");
    });
  }

  static async Event(event, subtype) {
    const obj = {};
    obj[`${event}.${subtype}`] = increment(1);
    await Firestore.AnonymouslySignIn();
    Firestore.Increment("events", obj);
  }

  static Increment(document, data) {
    const docRef = doc(db, "impact", document);
    updateDoc(docRef, data);
  }

  static async CountyImports() {
    Logger.debug("Querying Firestore for county records...");

    const docRef = doc(db, "imports", "COUNTIES");
    const docSnap = await getDoc(docRef);
    let data = docSnap.data();

    // Reformat county names: remove underscores and properly capitalize
    _.forEach(data, (v, k) => {
      v.id = k;
      v.name = _.startCase(_.lowerCase(_.replace(k, "_", " ")));
    });

    // Then sort and return
    return _.sortBy(data, [(v) => -v.population]);
  }

  static async StateImports() {
    Logger.debug("Querying Firestore for state records...");

    const q = await getDocs(collection(db, "imports"));
    // Skip COUNTIES docs, as "where" clause doesn't seem to work
    const docs = _.filter(q.docs, (d) => d.id !== "COUNTIES");

    // Restructure data
    const data = _.map(docs, (doc) => {
      return _.map(doc.data(), (v, date) => {
        return {
          id: `${doc.id}.${date}`,
          date: moment(date),
          election: doc.id,
          name: v.name,
          type: v.type,
          uploaded: v.uploaded,
          ballots: v.voters,
          importing: v.importing,
          voters: v.voters,
        };
      });
    });

    // Then sort and return
    return _.sortBy(_.flatten(data), ["date", "name"]).reverse();
  }

  static async Sampling() {
    Logger.debug("Querying Firestore for sample...");

    const voters = collection(db, "voters");
    const queries = {
      active_voters: query(
        voters,
        limit(2),
        where("years_active.2022", "==", true)
      ),
      infrequent_voters: query(
        voters,
        limit(2),
        where("has_ever_voted", "==", true),
        where("years_active.2022", "==", false)
      ),
      inactive_voters: query(
        voters,
        limit(2),
        where("has_ever_voted", "==", false)
      ),
      republicans: query(
        voters,
        limit(2),
        where("parties.Democrat", "==", false),
        where("parties.Republican", "==", true)
      ),
      democrats: query(
        voters,
        limit(2),
        where("parties.Democrat", "==", true),
        where("parties.Republican", "==", false)
      ),
      bipartisan: query(
        voters,
        limit(2),
        where("parties.Democrat", "==", true),
        where("parties.Republican", "==", true)
      ),
    };

    // Get results from all queries, merge into one array, then remove duplicates
    const fetchDocuments = await Promise.all(
      _.map(queries, async (q) => {
        return (await getDocs(q)).docs;
      })
    );
    return _.uniqBy(
      _.map(_.flatten(fetchDocuments), (d) => new VoterClass(d.data())),
      "id"
    );
  }

  static async Search(filter) {
    if (filter.isEmpty()) {
      return Firestore.Sampling();
    }
    Logger.debug("Querying Firestore...");

    const allFilters = await filter.all();
    const q = await getDocs(
      query(collection(db, "voters"), ...allFilters, limit(25))
    );
    return _.map(q.docs, (d) => new VoterClass(d.data()));
  }

  static async SearchFriend(filter) {
    Logger.debug("Querying Firestore...");

    const allFilters = await filter.all();
    const q = await getDocs(
      query(collection(db, "voters"), ...allFilters, limit(maxFriendsLimit))
    );
    return _.map(q.docs, (d) => new VoterClass(d.data()));
  }

  static async Testimonies() {
    Logger.debug("Querying Firestore for testimonies...");

    const q = await getDocs(collection(db, "testimonies"));
    const testimonies = _.map(q.docs, (d) => new Testimony(d.id, d.data()));
    return _.orderBy(testimonies, "score", "desc");
  }

  static async TotalImpact() {
    Logger.debug("Querying Firestore for impact...");

    const currentRef = doc(db, "impact", "events");
    const current = await getDoc(currentRef);
    const historicalRef = doc(db, "impact", "preserved");
    const historical = await getDoc(historicalRef);

    return {
      current: current.data(),
      historical: historical.data(),
    };
  }

  static async TotalStats() {
    Logger.debug("Querying Firestore for stats...");

    const docRef = doc(db, "aggregate", "  Statewide");
    const docSnap = await getDoc(docRef);

    return docSnap.data();
  }
}
