// assets
import {
  IconChecklist,
  IconMapPin,
  IconUserCheck,
  IconUserPlus,
} from "@tabler/icons";
import { FaShippingFast } from "react-icons/fa";

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const resources = {
  id: "resources",
  title: "Voting Resources",
  type: "group",
  children: [
    {
      id: "registered",
      title: "Am I Registered?",
      type: "item",
      url: "https://teamrv-mvp.sos.texas.gov/MVP/mvp.do",
      icon: IconUserCheck,
      breadcrumbs: false,
      external: true,
    },
    {
      id: "register",
      title: "Register to Vote",
      type: "item",
      url: "https://vrapp.sos.state.tx.us/index.asp",
      icon: IconUserPlus,
      breadcrumbs: false,
      external: true,
    },
    {
      id: "polls",
      title: "Where to Vote",
      type: "item",
      url: "https://www.vote.org/polling-place-locator/",
      icon: IconMapPin,
      breadcrumbs: false,
      external: true,
    },
    {
      id: "ballot",
      title: "What's on my Ballot?",
      type: "item",
      url: "https://www.vote411.org/ballot",
      icon: IconChecklist,
      breadcrumbs: false,
      external: true,
    },
    {
      id: "received",
      title: "Track my Mail-in Ballot",
      type: "item",
      url: "https://teamrv-mvp.sos.texas.gov/BallotTrackerApp/",
      icon: FaShippingFast,
      breadcrumbs: false,
      external: true,
    },
  ],
};

export default resources;
