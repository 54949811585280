// assets
import { IconBulb, IconChartArrowsVertical } from "@tabler/icons";

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const top = {
  id: "top",
  title: "Texas Election",
  type: "group",
  children: [
    {
      id: "insights",
      title: "Insights",
      type: "item",
      url: "/insights",
      icon: IconChartArrowsVertical,
      breadcrumbs: false,
    },
    {
      id: "impact",
      title: "Impact of this App",
      type: "item",
      url: "/impact",
      icon: IconBulb,
      breadcrumbs: false,
    },
  ],
};

export default top;
