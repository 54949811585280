import _ from "lodash";

import HowToVoteIcon from "@mui/icons-material/HowToVote";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

import config from "config";
import CustomLink from "./Link";

// ==============================|| LOGO ||============================== //

const Logo = ({ sx }) => {
  return (
    <List
      sx={{ ...sx, width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      style={{ padding: 0, width: "fit-content" }}
    >
      <CustomLink
        dest={_.replace(config.defaultPath, "/", "")}
        style={{ textDecoration: "unset" }}
      >
        <ListItem style={{ padding: 10, borderRadius: 24 }} button>
          <ListItemAvatar
            style={{
              border: 0,
              borderRadius: 24,
              background:
                "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)",
              color: "white",
              margin: "0px 15px",
            }}
          >
            <Avatar
              style={{
                backgroundColor: "transparent",
                color: "white",
                width: "initial",
              }}
            >
              <HowToVoteIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Texas Voter Tracker"
            secondary="Making public records more accessible"
          />
        </ListItem>
      </CustomLink>
    </List>
  );
};

export default Logo;
