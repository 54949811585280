// assets
import { IconAt, IconBulb } from "@tabler/icons";

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: "other",
  type: "group",
  children: [
    {
      id: "contact",
      title: "Submit Feedback",
      type: "item",
      url: "mailto:info@shivalicious.com",
      icon: IconAt,
      external: true,
    },
    {
      id: "portfolio",
      title: "Creator",
      type: "item",
      url: "https://shivalicious.com",
      icon: IconBulb,
      external: true,
    },
  ],
};

export default other;
