import _ from "lodash";
import moment from "moment";

import { thisElection } from "store/constant";

export default class VoterClass {
  constructor(data) {
    this.id = data.voter_id;
    this.name = data.name;
    this.name_original = _.get(data, "name_original");
    this.displayName = null;
    this.county = _.startCase(_.toLower(data.county));
    this.elections = _.orderBy(
      _.map(data.elections, (e, id) => {
        e.id = _.toInteger(id);
        return e;
      }),
      "date",
      "desc"
    );
    this.parties = data.parties;

    // For friends lookup
    this.original = null;
    this.results = 0;
  }

  getDisplayName() {
    if (this.displayName) {
      return this.displayName;
    }
    return _.startCase(_.toLower(this.name));
  }

  hasVoted() {
    return _.filter(this.elections, (e) => e.id === thisElection).length > 0;
  }

  lastVoted() {
    if (_.keys(this.elections).length > 0) {
      const sorted = _.sortBy(this.elections, "date");
      return moment(_.last(sorted).date);
    }
    return null;
  }

  party() {
    const { hasVotedDemocrat, hasVotedRepublican } = this.allComputedParties();

    if (hasVotedDemocrat && hasVotedRepublican) {
      return "Bipartisan";
    } else if (hasVotedDemocrat) {
      return "Democrat";
    } else if (hasVotedRepublican) {
      return "Republican";
    }
    return "Unknown";
  }

  /**
   * Some DB discrepancies exist and the boolean Democrat or Republican
   * party flags might not be properly set
   */
  allComputedParties() {
    let parties = {};

    // Get all declared parties from ballots that were cast
    _.forEach(this.elections, (e) => {
      if (e.party === "Democrat") {
        parties.hasVotedDemocrat = true;
      }
      if (e.party === "Republican") {
        parties.hasVotedRepublican = true;
      }
    });

    // Merge that with pre-saved metadata
    const metadataDemocrat = _.get(this.parties, "Democrat", false);
    const metadataRepublican = _.get(this.parties, "Republican", false);
    parties.hasVotedDemocrat = parties.hasVotedDemocrat || metadataDemocrat;
    parties.hasVotedRepublican =
      parties.hasVotedRepublican || metadataRepublican;

    return parties;
  }
}
