import _ from "lodash";
import moment from "moment";

import Analytics from "./analytics";
import Firestore from "./firestore";
import Logger from "./logger";

const CACHE = {
  signIn: _.memoize(Firestore.AnonymouslySignIn),
  county: _.memoize(Firestore.CountyImports),
  impact: _.memoize(Firestore.TotalImpact),
  state: _.memoize(Firestore.StateImports),
  sampling: _.memoize(Firestore.Sampling),
  search: _.memoize(Firestore.Search, (filter) => filter.key()),
  searchFriend: _.memoize(Firestore.SearchFriend, (filter) => filter.key()),
  stats: _.memoize(Firestore.TotalStats),
  testimonies: _.memoize(Firestore.Testimonies),
};

export default class Cache {
  static async ImportsCounty() {
    await CACHE.signIn();
    return CACHE.county();
  }

  static async ImportsState() {
    await CACHE.signIn();
    return CACHE.state();
  }

  static async ImportsPending() {
    await CACHE.signIn();
    const stateImports = await CACHE.state();
    return {
      pending: _.filter(
        stateImports,
        (state) => state.importing || state.voters === 0
      ).length,
      lastImport: moment(
        _.maxBy(stateImports, (state) => state.uploaded).uploaded
      ),
    };
  }

  static async LastImport() {
    const importsData = await Cache.ImportsPending();
    const lastImportMade = importsData.lastImport;
    const aggregateData = await Cache.TotalStats();
    const storedDate = _.get(aggregateData, "updated");

    if (!storedDate) {
      return lastImportMade;
    }
    const lastAggregated = moment(storedDate);
    if (lastImportMade.isBefore(lastAggregated)) {
      return lastAggregated;
    }
    return lastImportMade;
  }

  static async Search(filter) {
    await CACHE.signIn();

    if (filter.isEmpty()) {
      Analytics.oneMore("Sample_Loaded", Analytics.INDIVIDUAL);
      return CACHE.sampling();
    }
    Analytics.oneMore("Search_Made", Analytics.INDIVIDUAL);
    return CACHE.search(filter);
  }

  static async SearchFriend(filter, inDemoMode) {
    await CACHE.signIn();
    if (inDemoMode) {
      Logger.debug("In demo mode. Suppressing Analytics call");
    } else {
      Analytics.oneMore("Search_Made", Analytics.FRIEND);
    }
    return CACHE.searchFriend(filter);
  }

  static async Testimonies() {
    await CACHE.signIn();
    return CACHE.testimonies();
  }

  /**
   * Impact stats are computed based on current and historical metrics.
   *
   * Calculations are made either using current stats or preserved stats
   * from the 2022 election based on the specific metric being computed.
   * @returns object
   */
  static async TotalImpact() {
    await CACHE.signIn();
    const { current, historical } = await CACHE.impact();

    const hours_2020 = _.get(historical, ["2020", "time", "hours"]);
    const hours_2022 = _.get(historical, ["2022", "time", "hours"]);

    const inspected =
      _.get(historical, ["2022", "Voter_Inspected", "individual"]) +
      _.get(historical, ["2022", "Voter_Inspected", "friend"]);

    const resources = _.sum([
      _.get(current, ["Link_Clicked", "Am I Registered?"], 0),
      _.get(current, ["Link_Clicked", "Register to Vote"], 0),
      _.get(current, ["Link_Clicked", "Where to Vote"], 0),
      _.get(current, ["Link_Clicked", "What's on my Ballot?"], 0),
      _.get(current, ["Link_Clicked", "Check My Mail-in Ballot"], 0),
    ]);

    const searches_2020 =
      _.get(historical, ["2020", "Search_Made", "individual"]) +
      _.get(historical, ["2020", "Search_Made", "friend"]);
    const searches_2022 =
      _.get(historical, ["2022", "Search_Made", "individual"]) +
      _.get(historical, ["2022", "Search_Made", "friend"]);
    const current_searches =
      _.get(current, ["Search_Made", "individual"]) +
      _.get(current, ["Search_Made", "friend"]);

    const users_2020 = _.get(historical, ["2020", "users", "new"]);
    const users_2022 = _.get(current, ["users", "new"]);

    const likes_2020 = _.sum(_.values(_.get(historical, ["2020", "likes"])));
    const likes_2022 =
      _.get(historical, ["2022", "likes", "facebook"]) +
      _.get(historical, ["2022", "likes", "instagram"]);
    const likes_reddit_comments = _.sumBy(
      _.get(historical, ["2022", "likes", "reddit"]),
      (v) => _.max([0, v - 1])
    );
    const likes_reddit_posts = _.sum([
      _.get(historical, [
        "2022",
        "likes",
        "reddit_posts",
        "r/Austin",
        "known_upvotes",
      ]),
      _.get(historical, [
        "2022",
        "likes",
        "reddit_posts",
        "r/texas",
        "known_upvotes",
      ]),
    ]);
    const shares_2020 = _.sum(_.values(_.get(historical, ["2020", "shares"])));
    const shares_2022 = _.sum(_.values(_.get(historical, ["2022", "shares"])));

    return {
      hours: _.round(_.max([hours_2020, hours_2022])),
      inspected: inspected / searches_2022,
      resources: resources,
      searches: searches_2020 + current_searches,
      users: users_2020 + users_2022,
      likes:
        likes_2020 + likes_2022 + likes_reddit_comments + likes_reddit_posts,
      shares: shares_2020 + shares_2022,
    };
  }

  static async TotalStats() {
    await CACHE.signIn();
    return CACHE.stats();
  }
}
